<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="8">
        <CCard class="cardPrincipal">
          <CCardHeader class="cardHeader">
            <h2 class="text-center">Solicitud de Registro</h2>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="onSubmit">
              <CCard>
                <CCardHeader>
                    <h3 class="titulos">Datos de la Empresa</h3>
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol md="4">
                      <CInput size="sm"
                        label="Nombre de la Empresa"
                        v-model="formData.nombreEmpresa"
                        placeholder="Ingrese nombre de la empresa"
                        required
                      />
                    </CCol>
                    <CCol col="4">
                      <CInput size="sm"
                        @keyup="formatearRutEmpresa"
                        label="RUT de la Empresa"
                        v-model="formData.rutEmpresa"
                        placeholder="Ingrese RUT de la empresa"
                        required
                      />
                    </CCol>
                    <CCol md="4">
                      <CInput size="sm"
                        label="Telefono de contacto"
                        v-model="formData.telefono"
                        placeholder="Ingrese N° de telefono"
                        required
                        @keyup="formatearTelefono"
                      />
                      <p v-if="formData.telefonoError" style="color: red;" >{{ formData.telefonoError }}</p>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>

              <div v-for="(usuario, index) in formData.usuarios" :key="index" class="cardUsuario">
                <CCard>
                    <CCardHeader>
                        <h3 class="titulos">Datos Usuario {{ index == 0? 'Admin': index + 1 }}</h3>
                    </CCardHeader>
                  <CCardBody>
                    <CRow>
                      <CCol md="4">
                        <CInput size="sm"
                          :label="'Nombre'"
                          v-model="usuario.nombre"
                          :placeholder="'Ingrese Nombre'"
                          required
                        />
                      </CCol>
                      <CCol md="4">
                        <CInput size="sm"
                          :label="'Apellido'"
                          v-model="usuario.apellido"
                          :placeholder="'Ingrese Apellido'"
                          required
                        />
                      </CCol>
                      <CCol>
                        <CInput size="sm"
                            @keyup="formatearRutUsuario(usuario)"
                          :label="'RUT del Usuario'"
                          v-model="usuario.rutUsuario"
                          :placeholder="'Ingrese RUT del usuario'"
                          required
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <CInput size="sm"
                          :label="'Nombre de Usuario Vapp'"
                          v-model="usuario.nombreUsuarioX"
                          :placeholder="'Ingrese nombre de usuario Vapp'"
                          required
                        />
                      </CCol>
                      <CCol>
                        <CInput size="sm"
                          :label="'Correo Electrónico'"
                          v-model="usuario.correoUsuario"
                          type="email"
                          :placeholder="'Ingrese correo electrónico'"
                          required
                          :is-valid="usuario.validarCorreo"
                          @blur="validarCorreo(usuario)"
                        />
                      </CCol>
                    </CRow>
                    <CButton
                    v-if="formData.usuarios.length > 1"
                    color="danger"
                    @click="eliminarUsuario(index)"
                    class="btnEliminarUsuario"
                    >
                    Quitar Usuario
                    </CButton>
                  </CCardBody>
                </CCard>
              </div>
                <CButton size="sm" shape="pill"
                v-tippy="{
                    content: 'Puede registrar más usuarios después de registrarse',
                    followCursor: true,
                    placement: 'top',
                    theme: 'google',
                    animation: 'fade',
                    arrow: 'true',
                    arrowType: 'round',
                    delay: '100',
                    duration: '[200, 100]',
                  }"
                  @click="agregarUsuario"
                  class="btnAgregarUsuario"
                >
                  Agregar Usuario
                </CButton>

              <CRow>
                <CCol>
                    <CButton class="mt-4 w-100 btnNormalVappFormulario" @click="volverLogin" shape="pill">
                        Volver al Login
                    </CButton>
                </CCol>
                <CCol v-show="mostrarRegistrar">
                    <CButton :disabled="registrando" type="submit" class="mt-4 w-100 btnNormalVappFormulario" shape="pill">
                      <CIcon v-show="registrando" name="cil-loop-circular"class="imgr" /> Registrarse
                    </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>

import cons from '@/const'
import axios from "axios"
import * as util from '@/views/utils/utilsFunctions'

export default {
  name: "FormularioRegistro",
  data() {
    return {
      regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

      urlBase: cons.port + "://" + cons.ipServer,

      mostrarRegistrar: true,
      registrando: false,
      formData: {
        nombreEmpresa: "",
        rutEmpresa: null,
        rutEmpresaValidado: true,
        telefono: "",
        telefonoError: "",
        usuarios: [
          {
            nombre: "",
            apellido: "",
            nombreUsuarioX: "",
            rutUsuario: null,
            rutUsuarioValidado: true,
            correoUsuario: null,
            correoUsuarioValidado: true,
          },
        ],
      },
    };
  },
  methods: {
    agregarUsuario() {
      this.formData.usuarios.push({
        nombre: "",
        apellido: "",
        nombreUsuarioX: "",
        rutUsuario: "",
        correoUsuario: "",
      });
    },
    eliminarUsuario(index) {
      this.formData.usuarios.splice(index, 1);
    },
    volverLogin: function () {
        this.$router.push({ name: 'Login' });
    },
    onSubmit() {
      this.registrando = true
        let url = this.urlBase + "/registro_gesto/"
        axios({
            method: "POST",
            url: url,
            data: {
                datosRegistro: {
                    nombreEmpresa: this.formData.nombreEmpresa,
                    rutEmpresa: this.formData.rutEmpresa,
                    telefono: this.formData.telefono,
                    usuarios: this.formData.usuarios
                }
            },
        })
            .then((result) => {
                console.log(result)
                this.mostrarNotificaciones(1, result.data.mensajeGeneral)
                this.mostrarRegistrar = false
                //this.$router.push({ name: 'Login' });
            })
            .catch((error) => {
                let msjError = "Ups, lo sentimos no hemos podido procesar el inicio de Sesión"
                    
                    if (error.response) {
                        // El servidor respondió con un estado diferente de 2xx
                        msjError = `${error.response.data.mensajeGeneral}`;
                    } else if (error.request) {
                        // La solicitud fue hecha pero no se recibió respuesta
                        msjError = 'Ups, no se recibe respuesta desde el servidor, si el problema persiste contactar a soporte@vapp.cl, gracias';
                    } else if (error.message === 'Network Error') {
                        // Error de red específico
                        msjError = 'Ups, lo sentimos, pero no tenemos acceso a la url del servidor, si el problema persiste contactar a soporte@vapp.cl, gracias'
                    } else {
                        // Algo pasó al configurar la solicitud que provocó un error
                        msjError = `${error.message}`;
                    }
                    this.mostrarNotificaciones(3, msjError)
            })
            .finally(() => {
              this.registrando = false
            });
        },
        formatearRutEmpresa: function() {
            this.formData.rutEmpresa = util.formatearRut(this.formData.rutEmpresa)
            this.formData.rutEmpresaValidado = util.validarRut(this.formData.rutEmpresa)
        },
        formatearRutUsuario: function(usuario) {
            usuario.rutUsuario = util.formatearRut(usuario.rutUsuario)
            usuario.rutUsuarioValidado = util.validarRut(usuario.rutUsuario)
        },
        validarCorreo: function(usuario) {
            if (usuario.correoUsuario == "" || usuario.correoUsuario == null){
                usuario.correoUsuarioValidado = false
            } else {
                usuario.correoUsuarioValidado = this.regMail.test(usuario.correoUsuario)
            }
        },
        /* validarTelefono: function() {
            console.log("Input detectado: ", this.formData.telefono);

            // Elimina los caracteres que no son números y también elimina ceros iniciales
            const numeroFormatear = this.formData.telefono.replace(/[^0-9]/g, ""); 
            
            // Actualiza el valor formateado en el input
            this.formData.telefono = numeroFormatear;

            // Si aún quedan caracteres que no son números, se limpia el campo
            const soloNumeros = /^[0-9]*$/;
            if (!soloNumeros.test(this.formData.telefono)) {
                this.formData.telefonoError = "Solo se permiten números.";
            } else {
                this.formData.telefonoError = "";
            }
        }*/
        formatearTelefono () {
            const { numAFormatear, error } = util.validarTelefono(this.formData.telefono);

            this.formData.telefono = numAFormatear;
            this.formData.telefonoError = error;
        }
  },
};  
</script>

<style>

.cardPrincipal{
    margin-top: 15px;
    border-radius: 25px;
}

.cardHeader{
    margin-top: 15px;
    padding: 0;
}
.cardHeader h2{
  color: #1b1b1bcc;
}

.cardUsuario{
    padding: 0;
    margin-top: 5px;
}

.titulos{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    color: #252525cc;
}   

.btnAgregarUsuario {
    font-size: 16px;
    color: white;
    background-color:#25939D;
    border-color:#25939D;
    margin-top: 0;
    width: auto;
}

.btnEliminarUsuario{
    font-size: 13px;
}

</style>